<template>
  <div class="gaming-library profile-library bg-transparent px-4">
    <div class="col-lg-12">
      <div class="heading-section mb-5">
        <h4><em>Your Image</em> Library</h4>
      </div>
      <div v-if="isLoading">
        <b-skeleton animation="wave" width="30%"></b-skeleton>
        <b-skeleton animation="wave" width="70%"></b-skeleton>
        <b-skeleton animation="wave" width="70%"></b-skeleton>
      </div>
      <div class="images-library" v-if="!isLoading">
        <div class="item" :class="{'last-item': (images.length - 1) === index}" v-for="(image, index) in images" :key="index">
          <p class="mb-2">{{image.createdAt | timestamp}}</p>
          <h5 class="mb-2">{{getText(image)}}</h5>
          <p class="mb-3">{{getPrompt(image)}}</p>
          <div class="row mb-3" v-if="image.images && image.images.length">
            <div class="col-md-4" v-for="(img, index) in image.images" :key="index">
              <StoreImage :clickable="false" :allow-download="allowDownload" :show-download="true" :src="img"></StoreImage>
            </div>
          </div>
          <div class="main-border-button border-no-active" v-else-if="image.images === null">
            <a href="#">Uh-oh! The AI 🤖 tried its best, but it seems like creating the image turned into a wild adventure 🌪️</a>
          </div>
          <div class="main-border-button border-no-active" v-else>
            <a href="#">Processing...</a>
          </div>
          <div v-if="image.shortId">
            <link-stats :short-link="image.text"/>
          </div>
        </div>
        <div class="text-center p-5 bg-dark" v-if="images === null || images.length === 0">
          <div class="mb-4">
            Oh no! 😱 It seems our AI artists are currently taking a creative siesta and haven't generated any images yet.
          </div>
          <div class="main-button">
            <router-link to="/generate">Generate Now</router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters, mapState} from "vuex";
import StoreImage from "./StoreImage";
import {onAuthStateChanged} from "firebase/auth";
import LinkStats from "./LinkStats";

export default {
  name: "Library",
  data() {
    return {
      isLoading: true,
      dontShowText: ['WiFi']
    }
  },
  components: {LinkStats, StoreImage},
  computed: {
    ...mapState(['user', 'imageLibrary', 'userDetails']),
    ...mapGetters(['auth']),
    images() {
      return this.imageLibrary;
    },
    allowDownload() {
      return this.userDetails && !!this.userDetails.isSubscribed;
    },
  },
  filters: {
    timestamp(timestamp) {
      // Convert Firebase Timestamp to JavaScript Date object
      const date = timestamp.toDate();
      // Get the current time
      const currentTime = new Date();
      // Calculate the time difference
      const diffInMinutes = Math.floor((currentTime - date) / (1000 * 60));
      // Format the relative time string
      let relativeTime = "";
      if (diffInMinutes < 1) {
        relativeTime = "just now";
      } else if (diffInMinutes === 1) {
        relativeTime = "1 minute ago";
      } else if (diffInMinutes < 60) {
        relativeTime = `${diffInMinutes} minutes ago`;
      } else if (diffInMinutes < 1440) {
        const diffInHours = Math.floor(diffInMinutes / 60);
        relativeTime = `${diffInHours} hours ago`;
      } else if (diffInMinutes < 43200) {
        const diffInDays = Math.floor(diffInMinutes / 1440);
        relativeTime = `${diffInDays} days ago`;
      } else {
        relativeTime = date.toLocaleString(); // Fallback to the full date and time
      }
      return relativeTime;
    }
  },
  methods: {
    ...mapActions(['startImageLibrary', 'getImageUrl']),
    getText(image) {
      if (this.dontShowText.includes(image.integration)) {
        return image.integration;
      }
      if (image.shortId) {
        return image.originalLink ?? image.text;
      }
      return image.text;
    },
    getPrompt(image) {
      if (image.theme && image.themeId !== 1) {
        return "Theme: " + image.theme;
      }
      return image.prompt;
    },
  },
  mounted() {
    onAuthStateChanged(this.auth, (user) => {
      if (user) {
        this.startImageLibrary();
      }
      this.isLoading = false;
    });
  }
}
</script>

<style scoped lang="scss">
.bg-dark {
  color: #ffffff;
  background: #27292a !important;
}
.main-button {
  margin-bottom: -65px !important;
}
</style>
