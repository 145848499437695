<template>
  <div>
    <b-button class="stats-btn" v-if="!isLoading" @click="showModal">
      <b-icon icon="bar-chart-line"/> Show Stats
    </b-button>
    <b-button class="stats-btn" v-else>
      Loading...
    </b-button>
    <b-modal
        title="Statistics"
        v-model="modalVisible"
        :hide-footer="true" size="lg">
      <div class="modal-content" v-if="stats">
        <div class="row">
          <div class="col-md-6 mb-4">
            <!-- Total Clicks Card -->
            <div class="card bg-transparent">
              <div class="card-header bg-transparent text-light">
                Total Clicks
              </div>
              <div class="card-body">
                <p>Clicks: {{ stats.clicks }}</p>
                <p>Unique Clicks: {{ stats.unique_clicks }}</p>
              </div>
            </div>
          </div>
          <div class="col-md-12 mb-4">
            <!-- Daily Clicks Card -->
            <div class="card bg-transparent">
              <div class="card-header bg-transparent text-light">
                Visits
              </div>
              <div class="card-body">
                <ul class="list-unstyled">
                  <li v-for="dailyClick in stats.daily_clicks" :key="dailyClick.label">
                    {{ dailyClick.label }}:
                    Clicks: {{ dailyClick.total }} | Unique Clicks: {{ dailyClick.unique_total }}
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <!-- Countries Card -->
            <div class="card bg-transparent">
              <div class="card-header bg-transparent text-light">
                Countries
              </div>
              <div class="card-body">
                <ul class="list-unstyled">
                  <li v-for="country in stats.countries" :key="country.country_code">
                    <img :src="getFlagImage(country.country_code)" alt="Flag" class="country-flag" />
                    {{ getCountryName(country.country_code) }}:
                    Clicks: {{ country.total }} | Unique Clicks: {{ country.unique_total }}
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <!-- Platforms Card -->
            <div class="card bg-transparent">
              <div class="card-header bg-transparent text-light">
                Platforms
              </div>
              <div class="card-body">
                <ul class="list-unstyled">
                  <li v-for="platform in stats.platforms" :key="platform.platform">
                    {{ platform.platform }}:
                    Clicks: {{ platform.total }} | Unique Clicks: {{ platform.unique_total }}
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import shortLink from "../utils/short-link";
import countryCode from "../utils/country-code";

export default {
  data() {
    return {
      isLoading: false,
      stats: null,
      modalVisible: false,
      data: {
        labels: ['January', 'February', 'March'],
        datasets: [{ data: [40, 20, 12] }]
      },
      options: {
        responsive: true
      }
    };
  },
  props: {
    shortLink: {
      type: String,
      required: true
    }
  },
  methods: {
    async showModal() {
      this.isLoading = true;
      this.stats = await shortLink.getStats(this.shortLink);
      this.isLoading = false;
      this.modalVisible = true;
    },
    getFlagImage(code) {
      return `https://flagsapi.com/${code}/flat/64.png`;
    },
    getCountryName(code) {
      return countryCode.getName(code);
    },
  },
};
</script>

<style scoped>
.stats-btn {
  width: 200px;
  background: var(--background-color) !important;
  border: none;
}
.modal-content {
  /*max-height: 60vh;*/
}
.card {
  border: 1px solid white;
}
.card-header {
  border-bottom: 1px solid white;
}
.country-flag {
  width: 25px !important;
}
</style>
