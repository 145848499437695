<template>
  <div class="position-relative">
    <a v-if="clickable && imageUrl" :href="imageUrl" target="_blank">
      <img ref="image" :class="className" :src="imageUrl" :alt="alt">
    </a>
    <img ref="image" v-else-if="!clickable && imageUrl" :class="className" :src="imageUrl" :alt="alt">
    <div class="overflow-hidden" v-else>
      <b-skeleton-img width="100%" style="padding-top: 100%"></b-skeleton-img>
    </div>
    <div class="mt-1" v-if="showDownload && imageUrl">
      <div class="water-mark position-absolute">QRCodeFox.com</div>
      <b-link
          v-if="allowDownload"
          class="btn btn-primary w-100 btn-border"
          :href="imageUrl" target="_blank">
        Download <b-icon class="ms-1" icon="cloud-download"/>
      </b-link>
      <b-link
          v-if="!allowDownload"
          class="btn btn-secondary mb-2 w-100 btn-border"
          v-b-tooltip.hover title="Buy tokens to download the image.">
        Download <b-icon class="ms-1" icon="cloud-download"/>
      </b-link>
      <div v-if="!allowDownload" class="text-center text-light">
        <b-link to="/profile#" class="fw-bold"><u>Get tokens to download!</u></b-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "StoreImage",
  props: {
    src: {
      type: String,
    },
    alt: {
      type: String,
      default: "AI Generated QR code"
    },
    className: {
      type: String,
      default: "store-image img-border mb-0"
    },
    clickable: {
      type: Boolean,
      default: true
    },
    showDownload: {
      type: Boolean,
      default: false
    },
    allowDownload: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      imageUrl: null
    }
  },
  async mounted() {
    try {
      this.imageUrl = await this.$store.dispatch("getImageUrl", this.src);
    } catch (e) {
      this.imageUrl = null;
      console.log("Error", e);
    }
  }
}
</script>

<style scoped>
.img-border {
  border-radius: 24px 24px 0 0;
}
.btn-border {
  border-radius: 0 0 24px 24px;
}
.water-mark {
  color: var(--text-color);
  background: var(--background-color);
  font-size: 12px;
  font-weight: 700;
  top: 16px;
  right: 6px;
  margin: auto;
  padding: 10px;
  border-radius: 22px;
  width: fit-content;
  text-align: center;
}
</style>
