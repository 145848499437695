<template>
  <div class="row">
    <div class="col-lg-12">
      <div class="page-content"  v-if="user">

        <!-- ***** Banner Start ***** -->
        <div class="row">
          <div class="col-lg-12">
            <div class="main-profile ">
              <div class="row">
                <div class="col-lg-4">
                  <ProfilePicture style="border-radius: 23px;"/>
                </div>
                <div class="col-lg-4 align-self-center">
                  <div class="main-info header-text px-0 px-md-4">
                    <div class="mb-3">
                      <h4 class="mb-0">
                        {{user.displayName}}
                        <b-badge v-if="userDetails.isSubscribed" class="fw-bold mb-0">
                          Premium
                        </b-badge>
                        <b-badge v-else class="fw-bold mb-0 bg-secondary">
                          Free
                        </b-badge>
                      </h4>
                      <p>{{user.email}}</p>
                    </div>
<!--                    <p>-->
<!--                      🎟️ 1 Token = 1 Image 🖼✨ Let the magic begin! Collect tokens and unlock captivating visuals that will ignite your imagination. Prepare to be amazed! 🌟📸-->
<!--                    </p>-->
                    <div class="mt-4">
                      <div class="row mb-3 price-options">
                        <div class="col" v-for="(option, index) in options" :key="index">
                          <div class="item" :class="{'selected': selectedOption === index}" @click="onSelect(index)">
                            {{ option.tokens }}
                            <div class="featured" v-if="option.isFeatured">Popular</div>
                          </div>
                        </div>
                      </div>
                      <div class="features-list mb-3">
                        <b-icon class="primary-color" icon="check"/> Download QR codes<br>
                        <b-icon class="primary-color" icon="check"/> Customize QR codes<br>
                        <b-icon class="primary-color" icon="check"/> Access showcase prompts<br>
                        <b-icon class="primary-color" icon="check"/> Generate QR codes with priority<br>
                        <b-icon class="primary-color" icon="check"/> Receive priority customer support<br>
                      </div>
                      <stripe-checkout
                          ref="checkoutRef"
                          mode="payment"
                          :pk="publishableKey"
                          :line-items="lineItems"
                          :success-url="successURL"
                          :cancel-url="cancelURL"
                          :customerEmail="user.email"
                          :clientReferenceId="clientReferenceId"
                          @loading="onLoading"
                      />
                      <div class="checkout-btn mt-0">
                        <b-button class="fw-bold" v-if="!loading" pill @click="onCheckout">
                          Get {{ options[selectedOption].tokens }} Tokens for ₹{{ options[selectedOption].value }} ({{ options[selectedOption].pricing }})
                        </b-button>
                        <b-button v-else variant="success" pill disabled>
                          Loading...
                        </b-button>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-4 align-self-center">
                  <ul>
                    <li>Tokens <span>🎟️ {{ userDetails?.tokens }}</span></li>
                    <li>Images generated <span>{{ totalImages }}</span></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- ***** Banner End ***** -->

        <Library/>

      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters, mapState, mapActions} from "vuex";
import Library from "../components/Library";
import ProfilePicture from "../components/ProfilePicture";
import {onAuthStateChanged} from "firebase/auth";
import {StripeCheckout} from '@vue-stripe/vue-stripe';
import Swal from 'sweetalert2';

export default {
  name: "Profile",
  data() {
    return {
      tokens: 100,
      selectedOption: 1,
      options: [
        {
          name: 'Basic',
          tokens: 40,
          price: 'price_1NQcu1SCZHVEK144cge1HWJR',
          isFeatured: false,
          pricing: '$3',
          currency: 'INR',
          value: 249
        },
        {
          name: 'Standard',
          tokens: 100,
          price: 'price_1NQctASCZHVEK144zDJx4rpp',
          isFeatured: true,
          pricing: '$5',
          currency: 'INR',
          value: 399
        },
        {
          name: 'Pro',
          tokens: 200,
          price: 'price_1NQcsHSCZHVEK144tFl3ak40',
          isFeatured: false,
          pricing: '$10',
          currency: 'INR',
          value: 799
        }
      ],
      loading: false,
      clientReferenceId: null,
      publishableKey: process.env.STRIPE_PUBLISHABLE_KEY,
      lineItems: [
        {
          price: 'price_1NQctASCZHVEK144zDJx4rpp',
          quantity: 1,
        },
      ],
      successURL: `${location.origin}/profile?payment=success`,
      cancelURL: `${location.origin}/profile?payment=cancel`,
    }
  },
  computed: {
    ...mapState(['user', 'totalImages', 'userDetails']),
    ...mapGetters(['auth'])
  },
  components: {ProfilePicture, Library, StripeCheckout},
  methods: {
    ...mapActions(['checkout', 'updateTransaction', 'trackPurchaseEvent']),
    validateInput() {
      if (this.tokens <= 0) {
        this.tokens = 1;
      }
    },
    onSelect(selectedOption) {
      this.selectedOption = selectedOption;
      const optionValue = this.options[selectedOption];
      this.lineItems = [{
        price: optionValue.price,
        quantity: 1
      }];
    },
    async onCheckout() {
      try {
        const selected = this.options[this.selectedOption];
        this.clientReferenceId = await this.checkout({
          productId: selected.price,
          price: selected.pricing,
          tokens: selected.tokens
        });
        localStorage.setItem('purchaseInfo', JSON.stringify({
          transactionId: this.clientReferenceId,
          value: selected.value,
          currency: selected.currency,
          productId: selected.price,
          name: selected.name
        }));
        this.$refs.checkoutRef.redirectToCheckout();
      }
      catch (e) {
        this.loading = false;
        Swal.fire({
          icon: 'error',
          title: 'Payment Failed',
          text: 'Unable to initiate the payment. Please try again later.',
          timer: 5000
        }).then();
        const purchaseInfo = JSON.parse(localStorage.getItem('purchaseInfo') ?? '{}');
        if (purchaseInfo && purchaseInfo.transactionId) {
          await this.updateTransaction({
            transactionId: purchaseInfo.transactionId,
            status: 'error',
            errorMessage: e.message
          });
          localStorage.removeItem('purchaseInfo');
        }
      }
    },
    onLoading(loadingState) {
      this.loading = loadingState;
    }
  },
  async mounted() {
    let queryParams = this.$route.query;
    const payment = queryParams.payment;

    if (payment === 'success') {
      const purchaseInfo = JSON.parse(localStorage.getItem('purchaseInfo') ?? '{}');
      if (purchaseInfo && purchaseInfo.transactionId) {
        await this.updateTransaction({
          transactionId: purchaseInfo.transactionId,
          status: 'completed'
        });
        this.trackPurchaseEvent({
          transactionId: purchaseInfo.transactionId,
          value: purchaseInfo.value,
          currency: purchaseInfo.currency,
          productId: purchaseInfo.price,
          name: purchaseInfo.name
        });
        await Swal.fire({
          icon: 'success',
          title: 'Payment Successful!',
          text: 'Thank you for your payment.',
          timer: 5000
        });
        localStorage.removeItem('purchaseInfo');
      }
    }
    else if (payment === 'cancel') {
      const purchaseInfo = JSON.parse(localStorage.getItem('purchaseInfo') ?? '{}');
      if (purchaseInfo && purchaseInfo.transactionId) {
        await this.updateTransaction({
          transactionId: purchaseInfo.transactionId,
          status: 'cancelled'
        });
        await Swal.fire({
          icon: 'warning',
          title: 'Payment Cancelled',
          text: 'Your payment has been cancelled.',
          timer: 5000
        });
        localStorage.removeItem('purchaseInfo');
      }
    }

    onAuthStateChanged(this.auth, (user) => {
      if (!user) {
        this.$router.push('/home');
      }
    });
  }
}
</script>

<style lang="scss" scoped>
.border-color {
  border-color: #333333 !important;
}
.checkout-btn {
  .btn {
    width: 100%;
    color: var(--main-text-color);
    background: var(--main-color);
    border: none;
  }
}
.price-options {
  width: 300px;
  .item {
    position: relative;
    padding: 15px;
    border: 2px solid #242d3c;
    border-radius: 10px;
    text-align: center;
    color: #f7f7f7;
    cursor: pointer;
    font-weight: bold;
  }
  .selected {
    border: 2px solid var(--main-color);
    color: var(--main-color);
  }
  .featured {
    font-size: 10px;
    color: var(--main-text-color);
    background: var(--main-color);
    padding: 5px;
    border-radius: 5px;
    position: absolute;
    top: -15px;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    width: fit-content;
  }
}
.features-list {
  color: var(--text-color);
  font-size: 16px;
}
.primary-color {
  color: var(--main-color);
}
</style>
