const token = "FFoYGBUpvMZtXFUHuNNnphwlmSLP53HncIZr2NLOJJDIHHC0F2mmIW1T0lc6";

const headers = {
    "Authorization": `Bearer ${token}`,
    "Content-Type": "application/json",
    "Accept": "application/json",
};

const createShortLink = async (link) => {
    let body = {
        "long_url": link,
        "public_stats": true
    };
    const response = await fetch("https://t.ly/api/v1/link/shorten", {
        method: "POST",
        headers,
        body: JSON.stringify(body),
    });
    if (response.status !== 200) {
        throw ("Failed to create short-link");
    }
    return response.json();
}

const getStats = async (shortUrl) => {
    const response = await fetch(`https://t.ly/api/v1/link/stats?short_url=${shortUrl}`, {
        method: "GET",
        headers,
    });
    return response.json();
}

export default {
    createShortLink,
    getStats,
}
